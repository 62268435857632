import "./import/modules";
import "./import/components";

const video = document.getElementById("video");
const play = document.getElementById("play");
const pause = document.getElementById("pause");
const volume = document.getElementById("volume");
const menu_burger = document.querySelector(".menu_burger ");
const close__mob_menu = document.querySelector(".close__mob_menu ");
const mobile__menu = document.querySelector(".mobile__menu  ");
if (mobile__menu) {
  menu_burger.addEventListener("click", function () {
    mobile__menu.classList.add("mobile__menu_on");
  });

  close__mob_menu.addEventListener("click", function () {
    mobile__menu.classList.remove("mobile__menu_on");
  });
}
if (video) {

  if (play) {
    play.addEventListener("click", function () {
      play.style.display = "none";
      pause.style.display = "block";
      video.play();
    });
  }
  if (pause) {
    pause.addEventListener("click", function () {
      play.style.display = "block";
      pause.style.display = "none";
      video.pause();
    });
  }
  volume.addEventListener("click", function () {
    if (volume.classList.contains("volume__muted")) {
      volume.classList.remove("volume__muted");
      video.muted = false;
    } else {
      volume.classList.add("volume__muted");
      video.muted = true;
    }

  });

  video.addEventListener("ended", () => {
    document.location.href = "/rubrics.html";
  });


}
